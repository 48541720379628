import customerValidation from "@common/helpers/validations/customerValidation";
import { usePostalCodeMask } from "@common/hooks/useMask";
import { Province } from "@common/types/Province";
import { useTranslation } from "next-i18next";
import { ChangeEvent, forwardRef } from "react";
import { Path, RegisterOptions, UseFormRegister } from "react-hook-form";
import Form from ".";
import { Props as InputProps } from "./Input";

type Props = Omit<InputProps, "maxLength" | "minLength">;

const PostalCodeInput = forwardRef<HTMLInputElement, Props>(
  ({ id = "postalCode", defaultValue, onChange, ...props }: Props, ref) => {
    const { maskPipe: maskPostalCodePipe, unMaskPipe: unMaskPostalCodePipe } =
      usePostalCodeMask();

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const unmasked = unMaskPostalCodePipe(e.target.value);
      const masked = maskPostalCodePipe(unmasked);
      e.target.value = masked;

      if (onChange) onChange(e);
    };

    return (
      <Form.Input
        id={id}
        autoComplete="postal-code"
        minLength={7}
        maxLength={7}
        defaultValue={maskPostalCodePipe(defaultValue ?? "")}
        onChange={handleOnChange}
        ref={ref}
        {...props}
      />
    );
  }
);

type HookFormProps<T> = {
  register: UseFormRegister<T>;
  fieldKey: Path<T>;
  registerOptions?: RegisterOptions;
  provinceToValidate?: Province;
  requried?: boolean;
};

type WithHookFormProps<T> = Props & HookFormProps<T>;

const postalCodeValidation = customerValidation.postalCode;

export const PostalCodeInputWithValidation = <T,>({
  register,
  fieldKey,
  registerOptions,
  provinceToValidate,
  required = false,
  ...props
}: WithHookFormProps<T>) => {
  const { t } = useTranslation(["common"]);

  return (
    <PostalCodeInput
      label={t("common:postal_code")}
      suppressHotjar
      {...props}
      {...register(fieldKey, {
        required: {
          value: required,
          message: t("common:required"),
        },
        maxLength: postalCodeValidation.maxLength,
        validate: {
          matchingProvince: (value) =>
            postalCodeValidation.validateWithProvince(
              value as string,
              provinceToValidate
            ) || t("common:postal_code_and_province_mismatch"),
        },
        /**
         * This is a valid prop, but for w/e reason, eslint doesn't like it.
         */
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        pattern: {
          value: postalCodeValidation.pattern,
          message: t("common:invalid_postal_code"),
        },
        ...registerOptions,
      })}
    />
  );
};

export default PostalCodeInput;
