import {
  FocusEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useDateMask,
  useDateTimeMask,
  useMonthMask,
} from "@common/hooks/useMask";
import { format, setMonth, getMonth, Locale } from "date-fns";
import fr from "date-fns/locale/fr-CA";
import en from "date-fns/locale/en-CA";
import useDevice from "@common/hooks/useDevice";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { Props as InputProps } from "./Input";
import Form from ".";
import { CalendarTodayIcon, ChevronLeftIcon, ChevronRightIcon } from "../icons";

registerLocale("en", en);
registerLocale("fr", fr);

const smallDeviceCutoff = 667;

const dateToday = new Date();

const getRange = (
  lowEnd: Date | null | undefined,
  highEnd: Date,
  increment: number
) => {
  const years = [];
  const max = highEnd.getFullYear();
  let min = 0;

  if (lowEnd !== undefined) {
    min = lowEnd?.getFullYear() || 0;
  } else {
    min = dateToday.getFullYear() - 1;
  }

  for (let i = min; i <= max; i += increment) {
    years.push(i);
  }

  return years;
};

type CustomInputProps = InputProps;

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ id = "datePicker", value, onClick, className, ...props }, ref) => {
    return (
      <Form.Input
        id={id}
        type="text"
        rightIcon={<CalendarTodayIcon />}
        onClick={onClick}
        value={value}
        ref={ref}
        {...props}
      />
    );
  }
);

type DatePickerProps = Pick<
  ReactDatePickerProps,
  | "minDate"
  | "maxDate"
  | "excludeDates"
  | "selected"
  | "onChange"
  | "showTimeInput"
  | "showMonthYearPicker"
  | "popperPlacement"
  | "filterDate"
  | "placeholderText"
  | "locale"
> &
  Pick<
    CustomInputProps,
    | "id"
    | "label"
    | "disabled"
    | "status"
    | "feedback"
    | "hint"
    | "suppressHotjar"
  > & {
    iconPlacement?: "left" | "right";
  };

type DatePickerCustomHeaderProps = {
  minDate?: Date | null;
  maxDate?: Date | null;
  locale?: string | Locale;
} & ReactDatePickerCustomHeaderProps;

const customHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate,
  maxDate,
  locale = "en",
}: DatePickerCustomHeaderProps) => {
  const years = getRange(minDate, maxDate || dateToday, 1);
  const months = [...Array(12)].map((_, i) => ({
    value: i,
    label: format(setMonth(new Date(), i), "MMMM", {
      locale: locale === "en" ? en : fr,
    }),
  }));

  return (
    <div className="custom-date-header">
      <button
        type="button"
        onClick={decreaseMonth}
        aria-label="Previous Month"
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeftIcon className="w-5 h-5 text-gray-600 " />
      </button>
      <select
        value={date.getFullYear()}
        onChange={({ target: { value } }) => changeYear(parseInt(value, 10))}
        className="custom-date-header-select ml-1 rounded-l-lg"
        aria-label="Select year"
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={getMonth(date)}
        onChange={({ target: { value } }) => changeMonth(parseInt(value, 10))}
        className="custom-date-header-select mr-1 rounded-r-lg capitalize"
        aria-label="Select month"
      >
        {months.map(({ value, label }) => (
          <option key={value} value={value} className="m-1">
            {label}
          </option>
        ))}
      </select>

      <button
        type="button"
        onClick={increaseMonth}
        aria-label="Next Month"
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRightIcon className="w-5 h-5 text-gray-600" />
      </button>
    </div>
  );
};

const DatePicker = forwardRef<{ focus: () => void }, DatePickerProps>(
  (
    {
      id = "datepicker-custom-input",
      minDate,
      maxDate,
      showTimeInput = false,
      showMonthYearPicker = false,
      label,
      disabled,
      status,
      feedback = "",
      hint,
      popperPlacement,
      onChange,
      locale,
      suppressHotjar,
      iconPlacement = "right",
      ...props
    }: DatePickerProps,
    ref
  ) => {
    const maskDate = useDateMask(minDate, maxDate);
    const maskDateTime = useDateTimeMask(minDate, maxDate);
    const maskMonth = useMonthMask(minDate, maxDate);

    let dateFormat: string;
    if (showMonthYearPicker) {
      dateFormat = "MM/yyyy";
    } else if (showTimeInput) {
      dateFormat = "MM/dd/yyyy hh:mm aa";
    } else {
      dateFormat = "MM/dd/yyyy";
    }

    const handleChangeRaw = (e: FocusEvent<HTMLInputElement, Element>) => {
      if (!e.target.value) return;

      let mask;
      if (showMonthYearPicker) {
        mask = maskMonth;
      } else if (showTimeInput) {
        mask = maskDateTime;
      } else {
        mask = maskDate;
      }

      e.target.value = mask.maskPipe(e.target.value);
    };

    const datePickerRef = useRef<ReactDatePicker>(null);

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          datePickerRef.current?.setFocus();
        },
      };
    });

    const [showVirtualKeyboard, setShowVirtualKeyboard] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const device = useDevice();

    const { height: windowHeight } = useWindowDimensions();
    const isSmallDevice = windowHeight <= smallDeviceCutoff;

    return (
      <div className="[&>div]:w-full">
        <ReactDatePicker
          portalId="rr-datepicker-popper"
          timeInputLabel="Time:"
          dateFormat={dateFormat}
          calendarClassName="date-picker-wrapper"
          disabledKeyboardNavigation
          showTimeInput={showTimeInput}
          showMonthYearPicker={showMonthYearPicker}
          popperPlacement={popperPlacement}
          minDate={minDate}
          maxDate={maxDate}
          autoComplete="on"
          ref={datePickerRef}
          id={id}
          onCalendarClose={() => {
            setShowVirtualKeyboard(false);
          }}
          onSelect={() => {
            setShowVirtualKeyboard(false);
            setDatePickerIsOpen(false);
          }}
          onClickOutside={(e) => {
            const targetEl = e.target as HTMLElement;
            // If the click is outside the datepicker AND the associated input, close it
            if (targetEl.id !== id) {
              setShowVirtualKeyboard(false);
              setDatePickerIsOpen(false);
            }
          }}
          onFocus={(e) => {
            const { top, bottom } = e.target.getBoundingClientRect();
            if (bottom > windowHeight || top < 0) {
              e.target.scrollIntoView({ block: "start" });
            }
          }}
          onInputClick={() => {
            /**
             * To prevent date picker being cutoff on smaller devices, we hide the virtual keyboard and open the date picker on input click
             * When user click the input again, we show the virtual keyboard
             *
             * TODO: There is no clear way for user to know about this. So there might be a better way to do this.
             */
            if (datePickerIsOpen) {
              setShowVirtualKeyboard(true);
            } else {
              setDatePickerIsOpen(true);
            }

            if (!isSmallDevice) {
              const datepickerPopper = document.querySelector(
                ".react-datepicker-popper"
              );

              // scroll pop-up into view if it's cutoff
              if (datepickerPopper) {
                setTimeout(
                  () => {
                    const { top, bottom } =
                      datepickerPopper.getBoundingClientRect();
                    if (bottom > windowHeight || top < 0) {
                      datepickerPopper.scrollIntoView({ block: "start" });
                    }
                  },
                  // enough time for virtual keyboard to open
                  device?.isMobile ? 600 : 0
                );
              }
            }
          }}
          customInput={
            <CustomInput
              // "none" inputMode will disable virtual keyboard on mobile, defaults to undefined
              inputMode={
                (device?.isMobile && isSmallDevice && showVirtualKeyboard) ||
                (device?.isMobile && !isSmallDevice) ||
                device?.isDesktop
                  ? undefined
                  : "none"
              }
              autoComplete="on"
              id={id}
              label={label}
              disabled={disabled}
              status={status}
              feedback={feedback}
              hint={hint}
              suppressHotjar={suppressHotjar}
              leftIcon={
                iconPlacement === "left" ? <CalendarTodayIcon /> : undefined
              }
              rightIcon={
                iconPlacement === "right" ? <CalendarTodayIcon /> : undefined
              }
            />
          }
          renderCustomHeader={(customHeaderProps) =>
            customHeader({ ...customHeaderProps, minDate, maxDate, locale })
          }
          onChange={onChange}
          onChangeRaw={handleChangeRaw}
          locale={locale}
          {...props}
        />
      </div>
    );
  }
);

export default DatePicker;
